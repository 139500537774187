<template>
    <div>
        <v-card>
            <v-form
                v-model="evalForm"
                ref = 'valsEval'
                onSubmit="return false;"
            >
                <v-card-title :class="turnoColor+' lighten-3'">
                    
                    {{turnoCompleto}}
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="evalForm && !datosVal.cerrado"
                        :disabled="disableGuarda"
                        :loading="loading"
                        
                        small
                        color="green"
                        dark
                        rounded
                        @click="guarda(false)"
                    >
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>

                    <v-btn
                        v-if="datosVal.id && !datosVal.cerrado"
                        :disabled="!datosVal.id"
                        :loading="loading"
                        rounded
                        small
                        color="blue"
                        @click="cierra"
                    >
                        Cerrar
                    </v-btn>

                    <v-btn
                        v-if="muestraBorrar"
                        dark
                        x-small
                        color="red"
                        @click="borraEntrada(datosVal.id)"
                    >
                        <v-icon left>mdi-eraser</v-icon>
                        Borrar
                    </v-btn>
                    
                </v-card-title>
                <v-card-text>
                    <div class="mt-3">
                        <v-select
                            v-model="datosVal.aparatos"
                            :items="datosAparatos"
                            :readonly="Boolean(datosVal.cerrado)"
                            :rules="[
                                value => !!value || 'Requerido.',
                            ]"
                            label="Aparatos o sistemas alterados"
                            multiple
                            chips
                            rounded
                            outlined
                        >
                        </v-select>
                    </div>

                    <div>
                        <v-select
                            v-model="datosVal.orina"
                            :items="datosOrina"
                            :readonly="Boolean(datosVal.cerrado)"
                            :rules="[
                                value => !!value || 'Requerido.',
                            ]"
                            label="Orina"
                            multiple
                            chips
                            rounded
                            outlined
                        >
                        </v-select>
                    </div>

                    <div>
                        <v-select
                            v-model="datosVal.evacuacion"
                            :items="datosEvacuacion"
                            :rules="[
                                value => !!value || 'Requerido.',
                            ]"
                            :readonly="Boolean(datosVal.cerrado)"
                            label="Evacuación"
                            rounded
                            outlined
                        >
                        </v-select>
                    </div>
                    
                    <div v-if="muestraEvacua">
                        <v-row>
                            <v-col>
                                <v-switch
                                    class="d-inline"
                                    :readonly="Boolean(datosVal.cerrado)"
                                    v-model="datosVal.evacuacionMucosidad"
                                    label="Mucosidad"
                                    dense
                                    input-value=true
                                    
                                ></v-switch>

                            </v-col>
                            <v-col>
                                <v-switch
                                    class="d-inline"
                                    :readonly="Boolean(datosVal.cerrado)"
                                    v-model="datosVal.evacuacionSangre"
                                    dense
                                    label="Sangre"
                                ></v-switch>
                            </v-col>
                        </v-row>
                        
                        
                    </div>
                    
                    <div>
                        <v-textarea
                            v-model="datosVal.objetivos"
                            :readonly="Boolean(datosVal.cerrado)"
                            class="pt-4"
                            outlined
                            dense
                            :rules="[
                                value => !!value || 'Requerido.',
                            ]"
                            label="Datos OBJETIVOS"
                        ></v-textarea>
                    </div>

                    <div>
                        <v-textarea
                            v-model="datosVal.subjetivos"
                            :readonly="Boolean(datosVal.cerrado)"
                            class="pt-4"
                            outlined
                            dense
                            :rules="[
                                value => !!value || 'Requerido.',
                            ]"
                            label="Datos SUBJETIVOS"
                        ></v-textarea>
                    </div>
                    <span v-if="Boolean(datosVal.cerrado)">Por: {{datosVal.cierra}} {{datosVal.fechaCierra}}</span> 
                </v-card-text>
            </v-form>
        </v-card>
    </div>
</template>

<script>
export default {
    mounted(){
        this.cargaValTodas()
    },
    props:{
        turno: String,
        estaFecha: String,
        internamiento: Number,
        
    },
    data:()=>({
        loading:false,
        evalForm:false,
        rules2:[
            value => !!value || 'Requerido.',
            value => value.length > 0 || 'Requerido.'
        ],
        datosAparatos:[
            'Cardiorespiratorio',
            'Digestivo',
            'Urinario',
            'Genital FEM.',
            'Genital MASC.',
            'Endócrino',
            'Nervioso',
            'Tegumentario',
            'Musculi esquelético',
            'Linfático',
            'Aspectos psicológicos',
            'Emocionales y sociales',
            'Ninguno'
        ],
        datosOrina:[
            'Amarillo claro',
            'Concentrado',
            'Con sedimentos',
            'Hematuria',
            'Piuria',
            'Turbia',
            'No se requiere'
        ],
        datosEvacuacion:[
            'Sólida',
            'Pastosa',
            'Semi líquida',
            'Líquida',
            'Meconio',
            'No se requiere',
        ],
        datosVal:{
            id:null,
            aparatos:[],
            objetivos:'',
            subjetivos:'',
            orina:[],
            evacuacion:'',
            evacuacionMucosidad:false,
            evacuacionSangre:false,
            creado:'',
            dateCreated:'',
            //
        },
    }),
    methods:{
        async cargaValTodas(){
            this.loading = true
            this.datosVal={}
            this.$refs.valsEval.reset()
            /*
            if (this.turno =='mat')
                this.$refs.valsEvalmat.reset()
            if (this.turno =='ves')
                this.$refs.valsEvalves.reset()
            if (this.turno =='noc')
                this.$refs.valsEvalnoc.reset()
            */
            let params = {
                internamiento:this.internamiento,
                fecha: this.estaFecha,
                turno: this.turno
            }

            try{
                let rv = await this.$http({
                    methos:'GET',
                    url:'/enfermeria/listValoraciones',
                    params:params
                })
                this.loading = false
                //console.log(rv.data)
                if (rv.data.estado == true){
                    
                    rv.data.root.aparatos=rv.data.root.aparatos.split(',')
                    rv.data.root.orina=rv.data.root.orina.split(',')

                    this.datosVal = rv.data.root
                }
                else {
                    this.loading = false
                    this.datosVal.id = null
                    
                }
            }
            catch(err)
            {
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }
        },
        cierra(){
            this.loading = true
            this.$swal.fire({
                title: '¿Desea cerrar?',
                text: "Una vez cerrado no se podrá modificar",
                icon: 'question',
                showCancelButton: true,

                confirmButtonColor: 'green',
                cancelButtonColor: 'red',
                
                cancelButtonText: 'No',
                confirmButtonText: 'Si, Guarda'
            }).then( async (result) => {
                if (result.value) {
                    this.guarda(true)
                   
                } else {
                    this.loading=false
                }
            })
        },

        async guarda(cerrado){
            this.datosVal.evacuacionSangre?this.datosVal.evacuacionSangre=true:this.datosVal.evacuacionSangre=false
            this.datosVal.evacuacionMucosidad?this.datosVal.evacuacionMucosidad=true:this.datosVal.evacuacionMucosidad=false
            let params = this.datosVal
                params.internamiento=this.internamiento
                params.fecha = this.estaFecha
                params.turno = this.turno
                params.cerrado = cerrado
            try{
                let val = await this.$http({
                    method:'POST',
                    url:'/enfermeria/guardaValoracion',
                    data: params
                })

                if (val.data.estado == true){
                    this.cargaValTodas()
                    this.loading=false

                } else {
                    console.log(val.data.root)
                    this.loading = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                

            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }
        },
        tieneRol(rol){
            let contiene = this.$store.state.security.roles.includes(rol)
            return (contiene)
        },

        async borra2(id,motivo){
            try{
                let borra = await this.$http({
                    method:'DELETE',
                    url:'/enfermeria/borraValoracion',
                    data:{id:id, motivo:motivo}
                })
                if (borra.data.estado === true){
                    //this.$refs.valsEval.reset()
                    this.datosVal.cerrado=false
                    this.datosVal.fechaCierra=null
                    
                    this.cargaValTodas()
                } else {
                    console.log(borra.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede eliminar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        borraEntrada(id){
            //let motivo 
            this.$swal.fire({
                title: 'Borrar esta entrada',
                text: 'Motivo para borrar esta entrada',
                input: 'text',
                //inputValue:motivo,
                confirmButtonText: 'Borrar registro',
                showLoaderOnConformation: true,
                inputValidator:  (value) => {
                    if (value) {

                        this.$swal.fire({
                            title:'¿Desea borrar?',
                            text:'Esta acclón no se puede deshacer',
                            icon: 'warning',
                            showCancelButton: true
                        }).then((result)=>{
                            if (result.value){
                                this.borra2(id,value)
                            }
                        })
                    } else {
                        return 'Indique el motivo para esta eliminación'
                    }
                }
            })
        },
    },
    computed:{
        valsEval(){
            //console.log('valsEval'+this.turno)
            return ('valsEval'+this.turno)
        },
        turnoCompleto(){
            let text = ''

            switch (this.turno){
                case 'mat':
                    text = 'Matutino'
                    break
                case 'ves':
                    text = "Vespertino"
                    break
                case 'noc':
                    text = 'Nocturno'
                    break
            }

            return text
        },
        turnoColor(){
            let text = ''

            switch (this.turno){
                case 'mat':
                    text = 'blue'
                    break
                case 'ves':
                    text = "green"
                    break
                case 'noc':
                    text = 'red'
                    break
            }
            return text
        },
        disableGuarda(){
            if (this.evalForm){
                return false
            } else {
                return true
            }
        },
        muestraBorrar(){
            return (this.datosVal.id && this.datosVal.cerrado && (this.tieneRol('ROLE_ENFERMERIA_ADMIN')||this.tieneRol('ROLE_ADMIN')))
        },
        muestraEvacua(){
            if (this.datosVal.evacuacion && this.datosVal.evacuacion !='No se requiere'){
                return true
            } else {
                return false
            }
        },
    },
    watch:{
        estaFecha(){
            this.cargaValTodas(this.turno)
        }
    }
}
</script>

<style>

</style>